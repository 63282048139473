<template>
  <v-container
    class="px-sm-10 py-sm-10"
    fluid
    style="
      min-height: 100vh;
      background: #E6E6E6;
    "
  >
    <v-row>
      <v-col>
        <span
          style="cursor: pointer"
          @click="navBack"
        >
          <v-icon
            style="
            font-size: 16px;
            color: #7A1878;
          "
          >
            fas fa-chevron-left
          </v-icon>
          <span
            class="ml-1"
            style="color: #7A1878"
          >
            Kembali
          </span>
        </span>
      </v-col>
    </v-row>

    <v-row class="mt-n5">
      <v-col style="font-size: 26px">
        Detail Personal
      </v-col>
    </v-row>

    <v-row class="mt-n5">
      <v-col>
        <!-- Informasi Penyewa -->
        <v-card class="px-4 py-4">
          <v-row>
            <v-col class="font-common-black-bold">
              Informasi Penyewa
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Nama
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              {{ detail.name }}
              <span v-if="detail.profile_verified">
                <v-icon color="#E6E6E6">
                  mdi-checkbox-marked-circle
                </v-icon>
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Status
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              {{ detail.status_string }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Telepon
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              {{ detail.phone }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Email
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              {{ detail.email }}
              <span v-if="detail.email_verified">
                <v-icon color="#E6E6E6">
                  mdi-checkbox-marked-circle
                </v-icon>
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Alamat
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              {{ detail.address_string }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Newsletter
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              {{ newsLetter }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Tanggal Daftar
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              {{ detail.created_time }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Info Bank
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              {{ detail.bank_info }}
              <span v-if="detail.bank_verified">
                <v-icon color="green">
                  mdi-checkbox-marked-circle
                </v-icon>
              </span>
              <span v-else>
                <v-icon color="red">
                  mdi-close-circle
                </v-icon>
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Trapoin
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              {{ (detail.trapoin_saldo || 0) | dotThousandSeparator }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Total Transaksi
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              {{ detail.reseller_commission }}
            </v-col>
          </v-row>
        </v-card>

        <!-- Dokumen Penyewa -->
        <v-card class="px-4 py-4">
          <v-row>
            <v-col class="font-common-black-bold">
              Dokumen Penyewa
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Foto KTP
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              <template v-if="detail.doc_ktp !== null">
                <span>
                  {{ detail.doc_ktp.name + '(' + detail.doc_ktp.size + ')' }}
                </span>

                <v-icon
                  class="ml-1"
                  style="cursor: pointer"
                  @click="downloadAttachment(detail.doc_ktp)"
                >
                  fas fa-eye
                </v-icon>
              </template>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Foto Selfie KTP
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              <template v-if="detail.doc_ktp_selfie !== null">
                <span>
                  {{ detail.doc_ktp_selfie.name + '(' + detail.doc_ktp_selfie.size + ')' }}
                </span>

                <v-icon
                  class="ml-1"
                  style="cursor: pointer"
                  @click="downloadAttachment(detail.doc_ktp_selfie)"
                >
                  fas fa-eye
                </v-icon>
              </template>
            </v-col>
          </v-row>
        </v-card>

        <!-- Riwayat Komisi -->
        <v-card class="px-4 py-4">
          <v-row>
            <v-col
              cols="12"
              md="4"
              class="font-common-black-bold"
            >
              Riwayat Komisi
            </v-col>
            <v-col
              cols="12"
              md="4"
              class="px-5"
            >
              <span class="text-komisi-title text-purple">
                Total Komisi
              </span>
              <span class="text-komisi-value text-purple">
                Rp. 100,000,000
              </span>
            </v-col>
            <v-col
              cols="12"
              md="4"
              class="px-5"
            >
              <span class="text-komisi-title text-gray50">
                Total Penarikan Komisi
              </span>
              <span class="text-komisi-value text-gray50">
                Rp. 80,000,000
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-data-table
                :headers="headerRiwayatKomisiPenyewa"
                :items="riwayatKomisiPenyewa"
                :items-per-page="25"
                class="elevation-0"
                mobile-breakpoint="100"
                hide-default-footer
              >
                <template v-slot:item.code="{ item }">
                  <div
                    class="font-common"
                    style="
                      color: #7A1878;
                      cursor: pointer;
                    "
                    @click="toOrderDetail(item.hash)"
                  >
                    {{ item.code }}
                  </div>
                </template>

                <template v-slot:item.total_amount="{ item }">
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                    "
                  >
                    <span>
                      Rp
                    </span>

                    <span>
                      {{ item.total_amount | dotThousandSeparator }}
                    </span>
                  </div>
                </template>

                <template v-slot:footer>
                  <div class="text-center my-5 py-3">
                    <v-pagination
                      v-model="pageRiwayatKomisiPenyewa"
                      :length="pageCountRiwayatKomisiPenyewa"
                      color="purple"
                      :total-visible="5"
                      @input="onChangePageRiwayatKomisiPenyewa"
                    />
                    <span style="font-size: 12px; font-style: italic;">
                      Page {{ pageRiwayatKomisiPenyewa }} of {{ pageCountRiwayatKomisiPenyewa || 1 }}
                    </span>
                  </div>
                  <v-row class="mt-n16">
                    <v-col class="text-right pr-8">
                      <span style="font-size: 12px; font-style: italic;">
                        {{ itemCountRiwayatKomisiPenyewa + ' dari ' + itemTotalRiwayatKomisiPenyewa + ' ditampilkan' }}
                      </span>
                    </v-col>
                  </v-row>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>

        <!-- Riwayat Transaksi Penyewa -->
        <v-card class="px-4 py-4">
          <v-row>
            <v-col class="font-common-black-bold">
              Riwayat Transaksi Penyewa
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-data-table
                :headers="headerRiwayatPenyewa"
                :items="riwayatPenyewa"
                :items-per-page="25"
                class="elevation-0"
                mobile-breakpoint="100"
                hide-default-footer
              >
                <template v-slot:item.code="{ item }">
                  <div
                    class="font-common"
                    style="
                      color: #7A1878;
                      cursor: pointer;
                    "
                    @click="toOrderDetail(item.hash)"
                  >
                    {{ item.code }}
                  </div>
                </template>

                <template v-slot:item.total_amount="{ item }">
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                    "
                  >
                    <span>
                      Rp
                    </span>

                    <span>
                      {{ item.total_amount | dotThousandSeparator }}
                    </span>
                  </div>
                </template>

                <template v-slot:footer>
                  <div class="text-center my-5 py-3">
                    <v-pagination
                      v-model="pageRiwayatPenyewa"
                      :length="pageCountRiwayatPenyewa"
                      color="purple"
                      :total-visible="5"
                      @input="onChangePageRiwayatPenyewa"
                    />
                    <span style="font-size: 12px; font-style: italic;">
                      Page {{ pageRiwayatPenyewa }} of {{ pageCountRiwayatPenyewa || 1 }}
                    </span>
                  </div>
                  <v-row class="mt-n16">
                    <v-col class="text-right pr-8">
                      <span style="font-size: 12px; font-style: italic;">
                        {{ itemCountRiwayatPenyewa + ' dari ' + itemTotalRiwayatPenyewa + ' ditampilkan' }}
                      </span>
                    </v-col>
                  </v-row>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>

        <!-- Mutasi Trapoin -->
        <v-card class="px-4 py-4">
          <v-row>
            <v-col class="font-common-black-bold">
              Mutasi Trapoin
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-data-table
                :headers="headerMutasiTrapoin"
                :items="mutasiTrapoin"
                :items-per-page="25"
                class="elevation-0"
                mobile-breakpoint="100"
                hide-default-footer
              >
                <template v-slot:item.nominal="{ item }">
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                    "
                  >
                    <span :style="item.nominal < 0 ? 'color: #EA3E3A' : 'color: #5DB025'">
                      Rp
                    </span>

                    <span :style="item.nominal < 0 ? 'color: #EA3E3A' : 'color: #5DB025'">
                      {{ item.nominal | dotThousandSeparator }}
                    </span>
                  </div>
                </template>

                <template v-slot:item.description="{ item }">
                  <div class="font-common">
                    {{ item.description || '-' }}
                  </div>
                </template>

                <template v-slot:footer>
                  <div class="text-center my-5 py-3">
                    <v-pagination
                      v-model="pageMutasiTrapoin"
                      :length="pageCountMutasiTrapoin"
                      color="purple"
                      @input="onChangePageMutasiTrapoin"
                    />
                    <span style="font-size: 12px; font-style: italic;">
                      Page {{ pageMutasiTrapoin }} of {{ pageCountMutasiTrapoin || 1 }}
                    </span>
                  </div>
                  <v-row class="mt-n16">
                    <v-col class="text-right pr-8">
                      <span style="font-size: 12px; font-style: italic;">
                        {{ itemCountMutasiTrapoin + ' dari ' + itemTotalMutasiTrapoin + ' ditampilkan' }}
                      </span>
                    </v-col>
                  </v-row>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>

        <!-- BLOKIR PENYEWA -->
        <template v-if="detail.status === 1 && customerEdit">
          <v-row class="my-4">
            <v-col class="text-right">
              <v-btn
                style="
                  background: #EA3E3A;
                  width: 162px;
                  height: 46px;
                "
                @click="dialogBlokirPenyewa = true"
              >
                <span
                  class="text-capitalize font-common-white-bold"
                >
                  Blokir Penyewa
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </template>

        <!-- BUKA BLOKIR PENYEWA -->
        <template v-if="detail.status === 2 && customerEdit">
          <v-row class="my-4">
            <v-col class="text-right">
              <v-btn
                class="elevation-0"
                style="
                  background: #FAFAFA;
                  width: 162px;
                  height: 46px;
                "
                @click="dialogUnBlokirPenyewa = true"
              >
                <span
                  class="text-capitalize font-common-bold"
                >
                  Buka Blokir Penyewa
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>

    <!-- DIALOG BLOKIR PENYEWA -->
    <v-dialog
      v-model="dialogBlokirPenyewa"
      max-width="400"
    >
      <v-card class="px-10 py-8">
        <v-row>
          <v-col class="font-common-bold text-center">
            Apakah kamu yakin memblokir penyewa ini?
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              v-model="password"
              label="Masukkan Password"
              color="purple"
              outlined
              dense
              auto-complete="off"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="() => (showPassword = !showPassword)"
            />
          </v-col>
        </v-row>

        <v-row class="mt-n5">
          <v-col class="text-center">
            <v-btn
              class="text-capitalize elevation-0"
              style="
                width: 128px;
                height: 46px;
                background: #7A1878;
              "
              @click="blokirPenyewa(2)"
            >
              Ya, Lanjutkan
            </v-btn>

            <v-btn
              class="text-capitalize elevation-0"
              style="
                width: 70px;
                height: 46px;
                background: #F2F2F2;
              "
              @click="dialogBlokirPenyewa = false"
            >
              <span class="font-common-bold">
                Batal
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- DIALOG UN-BLOKIR PENYEWA -->
    <v-dialog
      v-model="dialogUnBlokirPenyewa"
      max-width="400"
    >
      <v-card class="px-10 py-8">
        <v-row>
          <v-col class="font-common-bold text-center">
            Apakah kamu yakin buka blokir penyewa ini?
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              v-model="password"
              label="Masukkan Password"
              color="purple"
              type="password"
              outlined
              dense
              auto-complete="off"
            />
          </v-col>
        </v-row>

        <v-row class="mt-n5">
          <v-col class="text-center">
            <v-btn
              class="text-capitalize elevation-0"
              style="
                width: 128px;
                height: 46px;
                background: #7A1878;
              "
              @click="blokirPenyewa(1)"
            >
              Ya, Lanjutkan
            </v-btn>

            <v-btn
              class="text-capitalize elevation-0"
              style="
                width: 70px;
                height: 46px;
                background: #F2F2F2;
              "
              @click="dialogUnBlokirPenyewa = false"
            >
              <span class="font-common-bold">
                Batal
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- IMAGE PREVIEW -->
    <div
      id="myModal"
      class="modal"
    >
      <span class="close">&times;</span>
      <img
        id="img01"
        class="modal-content"
      >
    </div>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    data: () => ({
      detail: {
        hash: '',
        avatar: '',
        status_string: '',
        name: '',
        phone: '',
        email: '',
        email_verified: 1,
        profile_verified: 1,
        profile_verified_string: '',
        company_name: null,
        wpoint_status: 1,
        ktp_verified: 1,
        ktp_num: '',
        doc_ktp: null,
        doc_ktp_selfie: null,
        ktp_img_path: {
          status: true,
          msg: 'Ok',
          data: {
            file_path: '',
          },
        },
        ktp_selfie_img_path: {
          status: true,
          msg: 'Ok',
          data: {
            file_path: '',
          },
        },
        address: null,
        address_string: '',
        poscode: null,
        newsletter_subscribe: 1,
        created_time: '',
        status: 1,
      },

      riwayat: {

      },

      // Riwayat Komisi Penyewa:
      headerRiwayatKomisiPenyewa: [
        { text: 'Kode Pesanan', align: 'start', value: 'inv_code', sortable: false },
        { text: 'Tanggal Pesanan', value: 'created_time' },
        { text: 'Komisi', align: 'center', value: 'commission_percent' },
        { text: 'Nominal Komisi', align: 'center', value: 'commission_nominal' },
        { text: 'Status', value: 'status_string', sortable: false },
      ],
      riwayatKomisiPenyewa: [],

      pageRiwayatKomisiPenyewa: 1,
      pageCountRiwayatKomisiPenyewa: 0,
      itemCountRiwayatKomisiPenyewa: 0,
      itemTotalRiwayatKomisiPenyewa: 0,

      // Riwayat Penyewa:
      headerRiwayatPenyewa: [
        { text: 'Kode Pesanan', align: 'start', value: 'code', sortable: false },
        { text: 'Tanggal Pesanan', value: 'created_time' },
        { text: 'Status', value: 'status_string', sortable: false },
        { text: 'Nominal', value: 'total_amount' },
      ],
      riwayatPenyewa: [],

      pageRiwayatPenyewa: 1,
      pageCountRiwayatPenyewa: 0,
      itemCountRiwayatPenyewa: 0,
      itemTotalRiwayatPenyewa: 0,

      // Mutasi Trapoin:
      headerMutasiTrapoin: [
        { text: 'Jenis Transaksi', align: 'start', value: 'type', sortable: false },
        { text: 'Tanggal Transaksi', value: 'tanggal', sortable: false },
        { text: 'Nominal', value: 'nominal', sortable: false },
        { text: 'Keterangan', value: 'description', sortable: false },
      ],
      mutasiTrapoin: [],

      pageMutasiTrapoin: 1,
      pageCountMutasiTrapoin: 0,
      itemCountMutasiTrapoin: 0,
      itemTotalMutasiTrapoin: 0,

      dialogBlokirPenyewa: false,
      dialogUnBlokirPenyewa: false,
      password: '',

      showPassword: false,

      // Permission:
      userMenus: localStorage.getItem('userMenus').split(','),
    }),

    computed: {
      ...mapState({
        itemsPerPage: state => state.setting.itemsPerPage,
      }),

      newsLetter () {
        let r = ''
        this.detail.newsletter_subscribe ? r = 'Langganan' : r = 'Tidak Berlangganan'

        return r
      },

      customerEdit () {
        let v = false
        if (this.userMenus.includes('CUSTOMER_EDIT')) v = true

        return v
      },
    },

    watch: {
    },

    created () {
      this.initialize()
      this.getRiwayatPenyewa()
      this.getMutasiTrapoin()
      this.getKomisiPenyewa()
    },

    methods: {
      initialize () {
        const vm = this

        const requestBody = {
          cust_hash: this.$route.params.id,
        }

        axios.post('/v2/t/customer/detail', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.detail = res.data.data.detail
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      getRiwayatPenyewa (page) {
        const vm = this

        const requestBody = {
          page: page,
          cust_hash: this.$route.params.id,
        }

        axios.post('/v2/t/customer/detail/invoice', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.riwayatPenyewa = res.data.data.list
            // pagination:
            vm.pageCountRiwayatPenyewa = res.data.data.pagination.page_total
            vm.itemCountRiwayatPenyewa = res.data.data.pagination.item_count
            vm.itemTotalRiwayatPenyewa = res.data.data.pagination.item_count_total
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      getKomisiPenyewa (page) {
        const vm = this

        const requestBody = {
          page: page,
          cust_hash: this.$route.params.id,
        }

        axios.post('/v2/t/customer/detail/commission', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.riwayatKomisiPenyewa = res.data.data.list
            console.log('komisi res: ', res)
            // pagination:
            vm.pageCountRiwayatKomisiPenyewa = res.data.data.pagination.page_total
            vm.itemCountRiwayatKomisiPenyewa = res.data.data.pagination.item_count
            vm.itemTotalRiwayatKomisiPenyewa = res.data.data.pagination.item_count_total
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      getMutasiTrapoin (page) {
        const vm = this

        const requestBody = {
          page: page,
          cust_hash: this.$route.params.id,
        }

        axios.post('/v2/t/customer/detail/trapoin', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.mutasiTrapoin = res.data.data.list
            // pagination:
            vm.pageCountMutasiTrapoin = res.data.data.pagination.page_total
            vm.itemCountMutasiTrapoin = res.data.data.pagination.item_count
            vm.itemTotalMutasiTrapoin = res.data.data.pagination.item_count_total
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      blokirPenyewa (status) {
        const requestBody = {
          cust_hash: this.$route.params.id,
          pwd: this.password,
          new_status: status,
        }

        axios.post('/v1/t/customer/mod/status', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.dialogBlokirPenyewa = false
            this.dialogUnBlokirPenyewa = false
            this.password = ''
            this.$toast.success('Berhasil mengubah status penyewa')
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      toOrderDetail (hash) {
        this.$router.push({ name: 'PesananDetail', params: { id: hash } })
      },

      onChangePageMutasiTrapoin (e) {
        this.initialize(e)
      },

      onChangePageRiwayatPenyewa (e) {
        this.initialize(e)
      },

      navBack () {
        this.$router.push({ name: 'Penyewa' })
      },

      downloadAttachment (data) {
        const type = data.type.toUpperCase()
        if (type === 'JPG' || type === 'JPEG' || type === 'PNG' || type === 'IMAGE') {
          const modal = document.getElementById('myModal')
          const modalImg = document.getElementById('img01')
          modal.style.display = 'block'
          if (data.url) modalImg.src = data.url
          if (data.path) modalImg.src = data.path
          const span = document.getElementsByClassName('close')[0]
          span.onclick = function () {
            modal.style.display = 'none'
          }
        } else if (type === 'PDF') {
          window.open(data.url, '_blank')
        } else {
          window.open(data.url_download, '_blank')
        }
      },
    },
  }
</script>

<style scoped>
.font-common {
  font-family: Lato;
  font-size: 16px;
  color: #808080;
}

.font-common-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #808080;
}

.font-common-black {
  font-family: Lato;
  font-size: 16px;
  color: #333333;
}

.font-common-black-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
}

.font-common-white {
  font-family: Lato;
  font-size: 16px;
  color: #FFFFFF;
}

.font-common-white-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #FFFFFF;
}

/* MODAL IMAGE  */
#myImg {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}
#myImg:hover {
  opacity: 0.7;
}
.modal {
  z-index: 10;
  display: none;
  position: fixed;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
}
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}
.modal-content,
#caption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}
@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}
@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}
.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}
/* END OF MODAL IMAGE */

.text-komisi-title{
  display: block;
  font-family: 'Lato';
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.text-komisi-value{
  display: block;
  font-family: 'Lato';
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
}
.text-gray50{
  color: #808080;
}
.text-purple{
  color: #7a1878;
}
</style>
